import { ValueProvider } from '@angular/core';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MAT_EXPANSION_PANEL_DEFAULT_OPTIONS } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SELECT_CONFIG } from '@angular/material/select';

const GA_FORM_FIELD_APPEARANCE = 'fill';
const GA_FORM_FIELD_FLOAT_LABEL = 'always';
const GA_FORM_FIELD_HIDE_REQ_MARKER = false;

export const GA_UI_KIT_PROVIDERS: ValueProvider[] = [
  {
    provide: MAT_EXPANSION_PANEL_DEFAULT_OPTIONS,
    useValue: {
      expandedHeight: '48px',
    },
  },
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: {
      appearance: GA_FORM_FIELD_APPEARANCE,
      floatLabel: GA_FORM_FIELD_FLOAT_LABEL,
      hideRequiredMarker: GA_FORM_FIELD_HIDE_REQ_MARKER,
    },
  },
  {
    provide: MAT_SELECT_CONFIG,
    useValue: {
      disableOptionCentering: true,
      overlayPanelClass: 'GA_SELECT_PANEL',
    },
  },
  { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
];
