import { FactoryProvider } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TitleStrategy } from '@angular/router';
import { Breadcrumbs } from './breadcrumbs';

export class BreadcrumbTitleStrategy extends TitleStrategy {
  constructor(
    private _breadcrumbs: Breadcrumbs,
    private _title: Title,
  ) {
    super();
  }

  updateTitle(): void {
    const title = this._breadcrumbs.value
      .map((b) => b.title)
      .reverse()
      .join(' - ');
    this._title.setTitle(title);
  }
}

function breadcrumbTitleStrategyFactory(
  breadcrumbs: Breadcrumbs,
  title: Title,
) {
  return new BreadcrumbTitleStrategy(breadcrumbs, title);
}

export const BREADCRUMB_TITLE_STRATEGY_PROVIDER: FactoryProvider = {
  provide: TitleStrategy,
  useFactory: breadcrumbTitleStrategyFactory,
  deps: [Breadcrumbs, Title],
};
