import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnauthorizedPageComponent } from 'gain-web/app/unauthorized-page/unauthorized-page.component';
import { ApplicationContextResolver } from 'gain-web/lib/application-context/application-context.resolver';
import { CountryRegionDataService } from 'gain-web/shared-modules/country-region-data/country-region-data.service';
import { UserContextResolver } from 'gain-web/shared-modules/user/user-context.resolver';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';

const routes: Routes = [
  {
    title: 'GAIN 3.0',
    path: '',
    runGuardsAndResolvers: 'always',
    resolve: {
      countryData: CountryRegionDataService,
      userContext: UserContextResolver,
      appContext: ApplicationContextResolver,
    },
    children: [
      {
        path: '',
        redirectTo: '/clients',
        pathMatch: 'full',
      },
      {
        path: 'clients',
        data: {
          level: 1,
        },
        loadChildren: () =>
          import('./clients/clients.module').then((m) => m.ClientsModule),
      },
      {
        path: 'support',
        data: {
          level: 1,
        },
        loadChildren: () =>
          import('./support/support.module').then((m) => m.SupportModule),
      },
      {
        path: 'uiux',
        loadChildren: () =>
          import('./ui-ux/uiux.module').then((m) => m.UiuxModule),
      },
      { path: 'unauthorized', component: UnauthorizedPageComponent },
      { path: '**', component: NotFoundPageComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
