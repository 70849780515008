import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { RuntimeConfigurationService } from 'gain-web/shared-modules/runtime-configuration/runtime-configuration.service';

interface IProperties {
  [key: string]: any;
}

@Injectable()
export class ApplicationInsightsService {
  applicationInsights?: ApplicationInsights;

  constructor(
    private _runtimeConfigurationService: RuntimeConfigurationService,
  ) {}

  init() {
    const instrumentationKey =
      this._runtimeConfigurationService.get()
        .applicationInsightsInstrumentationKey;

    if (instrumentationKey) {
      this.applicationInsights = new ApplicationInsights({
        config: {
          instrumentationKey,
          enableAutoRouteTracking: true,
          disableCookiesUsage: true,
        },
      });

      this.applicationInsights.loadAppInsights();
    }
  }

  logPageView(name?: string, uri?: string) {
    this.applicationInsights?.trackPageView({
      name,
      uri,
    });
  }

  logEvent(name: string, properties?: IProperties) {
    this.applicationInsights?.trackEvent({ name }, properties);
  }

  logMetric(name: string, average: number, properties?: IProperties) {
    this.applicationInsights?.trackMetric({ name, average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.applicationInsights?.trackException({ exception, severityLevel });
  }

  logTrace(message: string, properties: IProperties) {
    this.applicationInsights?.trackTrace({ message }, properties);
  }
}
