import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ApiClient } from 'gain-web/shared-services/api-client.generated.service';
import { findOrFetchApplicationContext } from './application-context.service';

@Injectable({ providedIn: 'root' })
export class ApplicationContextResolver
  implements Resolve<ApiClient.ApplicationContextDto>
{
  constructor(
    private _applicationContextService: ApiClient.ApplicationContextService,
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return findOrFetchApplicationContext(
      route,
      this._applicationContextService,
    );
  }
}
